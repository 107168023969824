<template>

    <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; flex: 1 1 auto;">

        <div>

            <label class="block text-900 text-xl font-medium mb-2" for="username">Имя пользователя</label>
            <InputText id="username" v-model="username" class="w-full mb-3" placeholder="Имя пользователя"
                       style="padding:1rem;" type="text"/>

            <label class="block text-900 font-medium text-xl mb-2" for="password1">Пароль</label>
            <Password id="password1" v-model="password" :feedback="false" :toggleMask="true"
                      class="w-full mb-7" inputClass="w-full" inputStyle="padding:1rem"
                      placeholder="Пароль"
                      @keyup.enter="login"></Password>


            <Button id="login_btn" :loading="isLoading" class="w-full p-3 text-xl" label="Войти"
                    @click="login"></button>

        </div>

    </div>

    <Notifies/>
    <BlockNotify/>
    <ConfirmPopup/>
    <Toast ref="toast"/>


</template>

<script>

import {BlockNotify, Notifies} from "@xnpmpackages/xcomponents";
import router from "../router"

export default {
    data() {
        return {
            isLoading: false,
            modalInfoVisible: true,
            modalInfoContent: '',

            username: '',
            password: '',
            checked: false
        }
    },
    methods: {

        login() {

            this.isLoading = true;

            this.$xapi.post('/enter', {
                action: 'login',
                username: this.username,
                password: this.password
            })
                .then(response => {

                    if (response.data.action === 'changePasswordRequired') {
                        this.$refs.ChangePasswordForm.show();
                    }

                    if (response.data?.elapsedDaysForChangePassword) {
                        this.$store.dispatch('addHeaderAnnounce', {
                            name: 'elapsedChangePasswordAnnounce',
                            text: 'До блокировки учетной записи осталось ' + response.data?.elapsedDaysForChangePassword + ' дней. Необходимо сменить пароль.'
                        });
                    } else {
                        this.$store.dispatch('removeHeaderAnnounce', 'elapsedChangePasswordAnnounce');
                    }

                    if (response.data.success === 'true') {
                        this.$store.commit('setUserInfo', response.data.user);
                        this.$store.commit('setUserPermissions', response.data.permissions);
                        if (this.$store.state.needRedirectTo) {
                            router.replace(this.$store.state.needRedirectTo);
                            this.$store.state.needRedirectTo = null;
                        } else {
                            const back = this.$router.options.history.state.back
                            if (back) {
                                this.$router.back();
                            } else {
                                router.replace('/');
                            }

                        }

                    }

                })
                .finally(() => {
                    this.isLoading = false;
                });

        }

    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        },

    },
    mounted() {
        // Пустой запрос. Axios intercepter поймает версию и запомнит. Обработчик находится в Xapi модуле.
        this.$xapi.post('/service', {action: "get_app_version"});
    },
    components: {
        BlockNotify,
        Notifies
    }
}
</script>

<style scoped>
.pi-eye {
    transform: scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform: scale(1.6);
    margin-right: 1rem;
}

</style>

